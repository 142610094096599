.card{
    width: 10rem;
    height: 15rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    padding: 0 26px 0 26px;
}

.card span:nth-of-type(2){
    color: var(--gray);
    font-size: 16px;
}
.card >img{
    transform: scale(0.6);
    margin-bottom: -2rem;
}
.c-button{
    background-color: #ffffff;
    box-shadow: 0 9px 60px rgba(0, 0, 0, 0.8);
    border-radius: 7px;
    border: none;
    padding:10px;
    font-size: 16px;
    color: #5290fd;
}


@media screen and (max-width:768px) {
    .card{
        height: 15rem;
        width: 8rem;
    }
}

@media screen and (max-width:580px) {
    .card{
        width: 8rem;
    }
}