.n-wrapper{
    height: 10vh;
    display: flex;
    justify-content: space-between;
}
.n-left{
    display: flex;
    flex: 1;
    align-items: center;
    gap: 2rem;
}
.n-name{
    font-size: 1.3rem;
    font-weight: bold;
}
.n-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.n-list{
    flex: 10;
}
.n-list>ul{
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-right: 2rem;
}
.n-list>ul>li{
    list-style: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.n-list>ul>li>.link-1, .link-2, .link-3, .link-4, .link-5{
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
  .n-list>ul>li>.link-1:hover, .link-2:hover, .link-3:hover, .link-4:hover, .link-5:hover{
    text-decoration: none;
    color: var(--orange);
  }


.n-button{
    flex: 2;
}

@media screen and (max-width: 768px) {
    .n-list>ul{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0rem;
        z-index: 1000;
        background-color: var(--gray);
        padding: 2rem;
        color: white;
        border-radius: 10px;
    }
    .n-button{
        display: none;
    }
}