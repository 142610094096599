.experience {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* gap: 5rem; */
    height: 12rem;
    margin-top: 14rem;
    margin-bottom: 14rem;
    /* darkmode */
    color: var(--black);
  }
  .experience  >h1 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--black);
  }
  .experience>span>p{
    width: 60vw;
    color: var(--gray);
    margin-top: -20px;
  }
  .achievement{
      display: flex;
      flex-direction: column;    
      align-items: center;
  }
  .exp{
    display: flex;
    flex-direction: row;
    gap: 6rem;
  }
  .circle {
      width: 4rem;
      height: 4rem;
      background: white;
      border-radius: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
  }
  .circle::before {
      border-radius: 100%;
      content: '';
      background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
      top: -8px;
      left: -8px;
      bottom: -8px;
      right: -8px;
      position: absolute;
      z-index:-1;
      box-shadow: var(--boxShadow);
  }
  .achievement span:nth-of-type(2){
      color: var(--orange);
  }


@media screen and (max-width:768px) {
  .experience{
    position: relative;
    top: 230px;
  }
}


@media screen and (max-width:480px) {
  .experience{
    position: relative;
    top: 1000px;
  }
  .experience>span>p{
    width: 90vw;
  }
  .exp{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

}